<template>
  <div>
    <div class="text-right mt-12 mb-8">
      <v-combobox
        v-model="selected_stat_type"
        :items="stat_types_items"
        label="Combobox"
        outlined
        dense
        @change="changeTab(selectedButton)"
      ></v-combobox>
      <v-btn :color="get_btn_color('this_month')" class="mr-2" @click="changeTab('this_month')"
        >By Days</v-btn
      >
      <v-btn :color="get_btn_color('month')"  class="mr-2" @click="changeTab('month')"
        >By Month</v-btn
      >
      <v-btn :color="get_btn_color('year')"  class="mr-2" @click="changeTab('year')"
        >By Year</v-btn
      >      
    </div>
    <div class="text-right">
      <v-select
          :items="month_labels"
          label="Select Month"
          outlined
          v-if="selectedButton=='this_month'"
          @change="update_month()"
          v-model="selected_month"
        ></v-select>
    </div>
    <div class="text-right">
      <v-select
          :items="years_labels"
          label="Select Year"
          outlined
          v-if="selectedButton=='month' || selectedButton=='this_month'"
          @change="update_year()"
          v-model="selected_year"
        ></v-select>
    </div>
    <div v-if="selectedChartData != null">
      <line-chart :width="650" :height="400" :chartData="chartData">
      </line-chart>
    </div>
  </div>
</template>

<script>
import LineChart from "Components/Charts/LineChart";
import { buySellChartData } from "Assets/data/buySellChartData.js";
import { ChartConfig } from "Constants/chart-config";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      test: "patate",
      buySellChartData,
      selectedButton: "this_month",
      selectedChartData: null,
      chartData: null,
      month_stats: [],
      day_stats: [],
      years_stats: {},

      selected_btn_map: {
        year: 0,
        month: 1,
        this_month: 2,
        week: 3,
      },
      stat_types_items: [
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Cost",
          value: "cost",
        },
        {
          text: "Draw Type",
          value: "draw_type",
        },
        {
          text: "Draw Type Count",
          value: "draw_type_count",
        },
        {
          text: "Multiplay",
          value: "multiplay",
        },
        {
          text: "Multiplier",
          value: "multiplier",
        },
        {
          text: "Order Count",
          value: "order_count",
        },
        {
          text: "Order Type",
          value: "order_type",
        },
        {
          text: "Profit",
          value: "profit",
        },
        {
          text: "Lottery Types",
          value: "type_name",
        },
      ],

      selected_stat_type: null,
      stat_types: {
        amount: { desc: "Total Sales Amount", data: "amount" },
        cost: { desc: "Total Cost", data: "cost" },
        draw_type: {
          desc: "Draw Type",
          data: "draw_type",
          choices: ["grids", "quickpicks"],
        },
        draw_type_count: {
          desc: "Draw Type Count",
          data: "draw_type_count",
          choices: ["grids", "quickpicks"],
        },
        multiplay: {
          desc: "Multiplay",
          data: "multiplay",
          choices: ["1", "5", "10", "15", "25"],
        },
        multiplier: {
          desc: "Multiplier",
          data: "multiplier",
          choices: ["yes", "no"],
        },
        order_count: {
          desc: "Order Count",
          data: "order_count",
        },
        order_type: {
          desc: "Order Type",
          data: "order_type",
          choices: ["INTERAC", "STRIPE"],
        },
        profit: {
          desc: "Profit",
          data: "profit",
        },
        type_name: {
          desc: "Lottery Types",
          data: "type_name",
          choices: ["megamillions", "powerball"],
        },
      },
      month_labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      years_labels: [],
      selected_month:"Jan",
      selected_year:"2021",
      colors: ["#283593", "#0097a7", "#0097a7", "#087212", "#b87321"],
    };
  },
  async mounted() {
    this.years_labels = [];
    // add the string 2021 to this year as an array
    var current_year = new Date().getFullYear();
    for (var year = 2021; year <= current_year; ++year) {
      this.years_labels.push(year.toString());
    }
    this.selected_stat_type = this.stat_types_items[0];
    var current_date = new Date();
    await this.fetchStats(current_date);
    this.selectedChart(this.selectedButton, current_date);
  },
  methods: {
    async fetchStats(fetch_date) {
      var month_int = fetch_date.getMonth() + 1;
      var month_str =
        month_int < 10 ? "0" + month_int.toString() : month_int.toString();

      var fetch_year = fetch_date.getFullYear();
      const current_year = new Date().getFullYear();
      this.selected_year = fetch_year.toString();
      this.selected_month = this.month_labels[month_int - 1];


      // Month
      for (var year = 2021; year <= current_year; ++year) {
        const yearDocRef = doc(
          collection(getFirestore(), "sales_stats"),
          year.toString()
        );

        if (this.month_stats[year] == undefined) {
          try {
          var documentSnapshot = await getDoc(yearDocRef);
          } catch (error) {
            console.error("Error fetching document:", error);
            // Handle the error appropriately
          }
          var year_content = documentSnapshot.data()
          this.month_stats[year] = year_content;
          this.years_stats[year] = year_content;
        }
      }

      const year_month_key = `${fetch_year.toString()}-${month_str}`;
      if (this.day_stats[year_month_key] == undefined) {
        const monthDocRef = doc(
          collection(getFirestore(), "sales_stats"),
          `${fetch_year.toString()}-${month_str}`
        );
        var documentSnapshot2 = await getDoc(monthDocRef);
        this.day_stats[year_month_key] = documentSnapshot2.data();
      }
    },
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    getData(current_date, stat_type_name) {

      var result = {
        labels: [],
        data: {},
      };
      var stat_type = this.stat_types[stat_type_name];
      var current_year = current_date.getFullYear();
      // Get a string with the year and the month with a 0 in front if needed
      var month_str =
        current_date.getMonth() + 1 < 10
          ? "0" + (current_date.getMonth() + 1).toString()
          : (current_date.getMonth() + 1).toString();
      const year_month_key = `${current_year.toString()}-${month_str}`;

      // Set empty arrays first
      if ("choices" in stat_type) {
        for (var init_choice of stat_type.choices) {
          result.data[init_choice] = [];
        }
      } else {
        result.data[stat_type_name] = [];
      }

      if (this.selectedButton == "month") {
        result.labels = this.month_labels;
        for (var month = 0; month < 12; ++month) {
          var month_key = (month + 1).toString();
          if (this.month_stats[current_year][month_key] == undefined) {
            if ("choices" in stat_type) {
              for (var choice of stat_type.choices) {
                result.data[choice].push(0);
              }
            } else {
              result.data[stat_type_name].push(0);
            }
          } else {
            if ("choices" in stat_type) {
              // eslint-disable-next-line no-redeclare
              for (var choice of stat_type.choices) {
                if (
                  stat_type_name in this.month_stats[current_year][month_key] &&
                  choice in this.month_stats[current_year][month_key][stat_type_name]
                ) {
                  result.data[choice].push(
                    this.month_stats[current_year][month_key][stat_type_name][choice]
                  );
                } else {
                  result.data[choice].push(0);
                }
              }
            } else {
              result.data[stat_type_name].push(
                this.month_stats[current_year][month_key][stat_type_name]
              );
            }
          }
        }
      } else if (this.selectedButton == "this_month") {
        var num_of_days = this.getDaysInMonth(
          current_date.getFullYear(),
          current_date.getMonth()
        );
        for (var day = 0; day < num_of_days; ++day) {
          var day_key = (day + 1);
          result.labels.push(day_key);
          if(this.day_stats[year_month_key] == undefined) {
            if ("choices" in stat_type) {
              // eslint-disable-next-line no-redeclare
              for (var choice of stat_type.choices) {
                result.data[choice].push(0);
              }
            } else {
              result.data[stat_type_name].push(0);
            }
          } else if (this.day_stats[year_month_key][day_key] == undefined) {
            if ("choices" in stat_type) {
              // eslint-disable-next-line no-redeclare
              for (var choice of stat_type.choices) {
                result.data[choice].push(0);
              }
            } else {
              result.data[stat_type_name].push(0);
            }
          } else {
            if ("choices" in stat_type) {
              // eslint-disable-next-line no-redeclare
              for (var choice of stat_type.choices) {
                if (
                  stat_type_name in this.day_stats[year_month_key][day_key] &&
                  choice in this.day_stats[year_month_key][day_key][stat_type_name]
                ) {
                  result.data[choice].push(
                    this.day_stats[year_month_key][day_key][stat_type_name][choice]
                  );
                } else {
                  result.data[choice].push(0);
                }
              }
            } else {
              result.data[stat_type_name].push(
                this.day_stats[year_month_key][day_key][stat_type_name]
              );
            }
          }
        }
      } else if (this.selectedButton == "year") {
        for (var year = 2021; year <= current_year; ++year) {
          result.labels.push(year.toString());


          if ("choices" in stat_type) {
            var total_choice = {};
            for (var month_number in this.years_stats[year]) {
              for (choice of stat_type.choices) {
                if (total_choice[choice] == undefined) {
                  total_choice[choice] = 0;
                }

                if (
                  stat_type_name in this.years_stats[year][month_number] &&
                  choice in this.years_stats[year][month_number][stat_type_name]
                ) {
                  total_choice[choice] += this.years_stats[year][month_number][stat_type_name][choice]
                } else {
                  total_choice[choice] = 0;
                }
              }
            }
            for (var choice_index in total_choice) {
              result.data[choice_index].push(total_choice[choice_index]);
            }

          } else {
            var total = 0;
            for (month_number in this.years_stats[year]) {
              total += this.years_stats[year][month_number][stat_type_name]
            }

            result.data[stat_type_name].push(total);
          }
        }
      }

      // Remove zeros at the end
      var max_index = 0;
      for (var data_choice_key in result.data) {
        var data_choice = result.data[data_choice_key];
        for (var i = data_choice.length - 1; i >= 0; i--) {
          if (data_choice[i] != 0) {
            if (i > max_index) {
              max_index = i;
            }
            break;
          }
        }
      }

      // eslint-disable-next-line no-redeclare
      for (var data_choice_key in result.data) {
        // eslint-disable-next-line no-redeclare
        var data_choice = result.data[data_choice_key];
        data_choice.length = max_index + 1;
      }
      return result;
    },
    selectedChart(selectedButton, current_date) {
      var data = this.getData(current_date, this.selected_stat_type.value);
      var datasets = [];
      var color_index = 0;
      for (var key in data.data) {
        var value = data.data[key];
        datasets.push({
          label: key,
          lineTension: 0.1,
          borderColor: this.colors[color_index],
          pointBorderColor: this.colors[color_index],
          pointBorderWidth: 2,
          pointRadius: 2,
          fill: false,
          pointBackgroundColor: ChartConfig.color.white,
          borderWidth: 3,
          data: value,
        });
        color_index++;
      }
      this.selectedChartData = 1;

      this.chartData = {
        labels: data.labels,
        datasets: datasets,
      };
    },
    async changeTab(selectedBtn) {
      this.selectedButton = selectedBtn;
      var current_date = new Date();
      await this.fetchStats(current_date);
      this.selectedChart(this.selectedButton, current_date);
    },
    async update_month() {
      var current_date = new Date();
      var month_int = this.month_labels.indexOf(this.selected_month);
      var year_int = parseInt(this.selected_year);
      current_date.setMonth(month_int);
      current_date.setFullYear(year_int);
      await this.fetchStats(current_date);
      this.selectedChart(this.selectedButton, current_date);
    },
    async update_year(){
      var current_date = new Date();
      var month_int = this.month_labels.indexOf(this.selected_month);
      var year_int = parseInt(this.selected_year);
      current_date.setMonth(month_int);
      current_date.setFullYear(year_int);
      await this.fetchStats(current_date);
      this.selectedChart(this.selectedButton, current_date);

    },
    get_btn_color(btn_name) {
      if (this.selectedButton == btn_name) {
        return "accent";
      } else {
        return "primary";
      }

    }
  },
};
</script>
